<template>
  <DemoPanel name="Chip">
    <v-col sm="12" md="6" lg="4">
      <Chip
        :color="options.color"
        :text="options.text"
        :small="options.small"
      />
    </v-col>
    <template v-slot:options>
      <FormSwitch label="small" v-model="options.small" />
      <v-col sm="4">
        <FormField v-model="options.text" />
      </v-col>
      <v-col sm="4"
        ><FormSelect
          label="Tipo do botão"
          v-model="options.color"
          :options="types"
      /></v-col>
    </template>
  </DemoPanel>
</template>

<script>
import FormSwitch from '@/components/form/FormSwitch'
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import DemoPanel from '../DemoPanel'
import Chip from '@/components/Chip'
export default {
  name: 'ChipDemo',
  components: { DemoPanel, Chip, FormField, FormSelect, FormSwitch },
  data: () => {
    return {
      options: {
        small: false,
        color: 'primario',
        text: 'Texto'
      },
      types: [
        { text: 'primario', value: 'primario' },
        { text: 'submissao', value: 'submissao' },
        { text: 'auxiliar', value: 'auxiliar' },
        { text: 'perigo', value: 'perigo' },
        { text: 'alerta', value: 'alerta' },
        { text: 'informacao', value: 'informacao' },
        { text: 'Sem cor', value: '' }
      ]
    }
  }
}
</script>
