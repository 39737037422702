<template>
  <DemoPanel name="LineDivider">
    <v-col sm="12" md="6" lg="4">
      <LineDivider>
        <h3>Linha divisória</h3>
      </LineDivider>
    </v-col>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import LineDivider from '@/components/LineDivider'
export default {
  name: 'LineDividerDemo',
  components: { DemoPanel, LineDivider },
  data: () => ({})
}
</script>
