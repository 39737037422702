<template>
  <DemoPanel name="FormSelect">
    <v-col sm="12" md="4" lg="4">
      <FormSelect
        v-model="selecao"
        label="Usuário"
        :validation="$v.selecao"
        :dense="option.dense"
        :solo="option.solo"
        :disabled="option.disabled"
        :readonly="option.readonly"
        :options="options"
      ></FormSelect>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="dense" v-model="option.dense" />
      <FormSwitch label="solo" v-model="option.solo" />
      <FormSwitch label="disabled" v-model="option.disabled" />
      <FormSwitch label="readonly" v-model="option.readonly" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormSelectDemo',
  components: { DemoPanel, FormSelect, FormSwitch },
  data: () => ({
    selecao: '',
    options: [
      { text: 'Opção 1', value: 'opt1' },
      { text: 'Opção 2', value: 'opt2' },
      { text: 'Opção 3', value: 'opt3' },
      { text: 'Opção 4', value: 'opt4' },
      { text: 'Opção 5', value: 'opt5' }
    ],
    option: {
      dense: false,
      solo: false,
      disabled: false,
      readonly: false
    }
  }),
  validations: {
    selecao: {
      required
    }
  }
}
</script>
