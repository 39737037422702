<template>
  <DemoPanel name="FormField">
    <v-col sm="12" md="4" lg="4">
      <FormField
        v-model="usuario"
        label="Usuário"
        :validation="$v.usuario"
        :dense="option.dense"
        :solo="option.solo"
        :disabled="option.disabled"
        :readonly="option.readonly"
      ></FormField>
    </v-col>
    <v-col sm="12" md="4" lg="4">
      <FormField
        v-model="senha"
        label="Senha"
        type="password"
        :validation="$v.senha"
        :dense="option.dense"
        :solo="option.solo"
        :disabled="option.disabled"
        :readonly="option.readonly"
      ></FormField>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="dense" v-model="option.dense" />
      <FormSwitch label="solo" v-model="option.solo" />
      <FormSwitch label="disabled" v-model="option.disabled" />
      <FormSwitch label="readonly" v-model="option.readonly" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormFieldDemo',
  components: { DemoPanel, FormField, FormSwitch },
  data: () => ({
    usuario: '',
    senha: '',
    option: {
      dense: false,
      solo: false,
      disabled: false,
      readonly: false
    }
  }),
  validations: {
    usuario: {
      required
    },
    senha: {
      required
    }
  }
}
</script>
