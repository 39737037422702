<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-form">
        Formulário
      </v-tab>
      <v-tab href="#tab-outros">
        Componentes
      </v-tab>

      <v-tab-item value="tab-form" class="tab-content">
        <FormButton />
        <FormButtonMenu />
        <FormField />
        <FormDatePicker />
        <FormSelect />
        <FormRadio />
        <FormRadioGroup />
        <FormSwitch />
      </v-tab-item>
      <v-tab-item value="tab-outros" class="tab-content">
        <Card />
        <Table />
        <Divider />
        <Chip />
        <Map />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import FormField from './components/FormField'
import FormDatePicker from './components/FormDatePicker'
import FormSelect from './components/FormSelect'
import FormRadio from './components/FormRadio'
import FormRadioGroup from './components/FormRadioGroup'
import FormSwitch from './components/FormSwitch'
import FormButton from './components/FormButton'
import FormButtonMenu from './components/FormButtonMenu'
import Table from './components/Table'
import Card from './components/Card'
import Divider from './components/Divider'
import Chip from './components/Chip'
import Map from './components/Map'
export default {
  name: 'Demo',
  components: {
    FormDatePicker,
    FormField,
    FormSelect,
    FormRadio,
    FormRadioGroup,
    FormSwitch,
    Card,
    Chip,
    Map,
    Table,
    Divider,
    FormButton,
    FormButtonMenu
  },
  data: () => ({
    tab: null
  })
}
</script>

<style lang="scss">
.tab-content {
  margin-top: 25px;
}
</style>
