<template>
  <DemoPanel name="Card">
    <v-col sm="12" md="6" lg="4">
      <Card :loading="option.loading" :shadown="option.shadown">
        <h3>Component de card</h3>
        <p>Este é um componente de card padrão</p>
      </Card>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="loading" v-model="option.loading" />
      <FormSwitch label="shadown" v-model="option.shadown" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import Card from '@/components/Card'
import FormSwitch from '@/components/form/FormSwitch'
export default {
  name: 'CardDemo',
  components: { DemoPanel, Card, FormSwitch },
  data: () => ({
    option: {
      loading: false,
      shadown: false
    }
  })
}
</script>
