<template>
  <DemoPanel name="FormButton">
    <v-col sm="12" md="4" lg="4">
      <FormButton
        :disabled="option.disabled"
        :full="option.full"
        :submit="option.submit"
        :icon="option.icon"
        :beforeIcon="option.beforeIcon ? 'far fa-arrow-alt-circle-left' : ''"
        :afterIcon="option.afterIcon ? 'far fa-arrow-alt-circle-right' : ''"
        :type="option.type"
      >
        <v-icon v-if="option.icon" size="15">fas fa-check</v-icon>
        <span v-else>Um botão</span>
      </FormButton>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="icon before text" v-model="option.beforeIcon" />
      <FormSwitch label="icon after text" v-model="option.afterIcon" />
      <FormSwitch label="Button icon" v-model="option.icon" />
      <FormSwitch label="Button submit" v-model="option.submit" />
      <FormSwitch label="Button full size" v-model="option.full" />
      <FormSwitch label="disabled" v-model="option.disabled" />
      <v-col sm="4"
        ><FormSelect
          label="Tipo do botão"
          v-model="option.type"
          :options="tipos"
      /></v-col>
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormButton from '@/components/form/FormButton'
import FormSelect from '@/components/form/FormSelect'
import FormSwitch from '@/components/form/FormSwitch'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormButtonDemo',
  components: { DemoPanel, FormButton, FormSelect, FormSwitch },
  data: () => ({
    usuario: '',
    senha: '',
    option: {
      submit: false,
      full: false,
      disabled: false,
      readonly: false,
      beforeIcon: false,
      afterIcon: false,
      type: 'primario'
    },
    tipos: [
      { text: 'primario', value: 'primario' },
      { text: 'submissao', value: 'submissao' },
      { text: 'auxiliar', value: 'auxiliar' },
      { text: 'perigo', value: 'perigo' },
      { text: 'alerta', value: 'alerta' },
      { text: 'informacao', value: 'informacao' },
      { text: 'Sem cor', value: '' }
    ]
  }),
  validations: {
    usuario: {
      required
    },
    senha: {
      required
    }
  }
}
</script>
