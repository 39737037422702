<template>
  <DemoPanel name="FormSwitch">
    <v-col sm="12" md="4" lg="4">
      <FormSwitch
        v-model="selecao"
        :label="selecao ? 'Ativo' : 'Inativo'"
        :validation="$v.selecao"
        :disabled="option.disabled"
        :readonly="option.readonly"
      ></FormSwitch>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="disabled" v-model="option.disabled" />
      <FormSwitch label="readonly" v-model="option.readonly" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormSwitch from '@/components/form/FormSwitch'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormSwitchDemo',
  components: { DemoPanel, FormSwitch },
  data: () => ({
    selecao: false,
    option: {
      disabled: false,
      readonly: false
    }
  }),
  validations: {
    selecao: {
      required
    }
  }
}
</script>
