<template>
  <Card class="DemoCard">
    <template v-slot:content-full>
      <v-app-bar color="bar-color-blue" dense flat dark>
        <v-toolbar-title>{{ name }}</v-toolbar-title>
      </v-app-bar>
    </template>
    <v-col sm="12">
      <v-row justify="center">
        <slot name="options"></slot>
      </v-row>
      <v-row>
        <v-row justify="center">
          <slot></slot>
        </v-row>
      </v-row>
    </v-col>
  </Card>
</template>

<script>
import Card from '@/components/Card'
export default {
  name: 'DemoPanel',
  components: { Card },
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.bar-color-blue {
  background-color: var(--info-color);
  color: #fff;
}

.DemoCard {
  margin-bottom: 25px;
}
</style>
