<template>
  <DemoPanel name="FormButtonMenu">
    <v-col sm="12" md="4" lg="4">
      <FormButtonMenu
        :disabled="option.disabled"
        :icon="option.icon"
        :options="btnOptions"
        :flat="option.flat"
        :type="option.type"
      >
        <v-icon v-if="option.icon" size="15">fas fa-caret-square-down</v-icon>
        <span v-else>Dropdown</span>
      </FormButtonMenu>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="icon" v-model="option.icon" />
      <FormSwitch label="Flat" v-model="option.flat" />
      <FormSwitch label="disabled" v-model="option.disabled" />
      <v-col sm="4"
        ><FormSelect
          label="Tipo do botão"
          v-model="option.type"
          :options="tipos"
      /></v-col>
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormButtonMenuDemo',
  components: { DemoPanel, FormButtonMenu, FormSwitch, FormSelect },
  data: () => ({
    usuario: '',
    senha: '',
    option: {
      disabled: false,
      flat: false,
      icon: false,
      type: ''
    },
    tipos: [
      { text: 'Sem cor', value: '' },
      { text: 'primario', value: 'primario' },
      { text: 'submissao', value: 'submissao' },
      { text: 'auxiliar', value: 'auxiliar' },
      { text: 'perigo', value: 'perigo' },
      { text: 'alerta', value: 'alerta' },
      { text: 'informacao', value: 'informacao' }
    ],
    btnOptions: [
      { text: 'Teste', value: 'teste' },
      { text: 'Teste dois', value: 'teste dois' }
    ]
  }),
  validations: {
    usuario: {
      required
    },
    senha: {
      required
    }
  }
}
</script>
