<template>
  <DemoPanel name="Map">
    <v-col sm="12" md="12" lg="12" style="height:67vh;width:100%;">
      <Map
        v-if="show"
        ref="map"
        :coords="options.coords"
        :editable="options.editable"
        @get-data="getData"
        @receive-coords="receiveCoords"
      >
        <template v-slot:remove="{}">
          <FormButton
            class="clear-layer-btn"
            type="default"
            @click="$refs.map.clearLayers(true)"
          >
            <v-icon size="15">fas fa-trash-alt</v-icon>
          </FormButton>
        </template>
        <template v-slot:save="{}">
          <div class="bottom-save-btn">
            <FormButton type="primario" @click="$refs.map.getData()">
              <span>Salvar</span>
            </FormButton>
          </div>
        </template>
      </Map>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="editable" v-model="options.editable" />
      <v-col sm="4">
        <FormField label="latitude" v-model="coords.lat" disabled />
      </v-col>
      <v-col sm="4">
        <FormField label="longuitude" v-model="coords.lng" disabled />
      </v-col>
    </template>
  </DemoPanel>
</template>

<script>
import FormSwitch from '@/components/form/FormSwitch'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import DemoPanel from '../DemoPanel'
import Map from '@/components/Map'
export default {
  name: 'MapDemo',
  components: { DemoPanel, Map, FormButton, FormField, FormSwitch },
  data: () => {
    return {
      coords: {
        lat: 0,
        lng: 0
      },
      options: {
        editable: false,
        color: 'primario',
        coords: {
          lat: 0,
          lng: 0
        }
      },
      show: true
    }
  },
  watch: {
    'options.editable'() {
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 100)
    }
  },
  methods: {
    receiveCoords(coords) {
      if (coords) {
        this.coords = coords
      }
    },
    getData(data) {
      alert(JSON.stringify(data))
    }
  }
}
</script>
