<template>
  <DemoPanel name="Table">
    <v-col sm="12">
      <DataTable
        :headers="headers"
        sortBy="name"
        :sortDesc="true"
        search
        :data="rows"
        :filters="filters"
        :showSelect="option.showSelect"
        :singleSelect="option.singleSelect"
        :noHideFilters="option.noHideFilters"
        ref="datatable"
      >
        <template v-slot:opcoes="{ row }">
          <FormButtonMenu icon :options="tableOptions">
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="show select" v-model="option.showSelect" />
      <FormSwitch label="single select" v-model="option.singleSelect" />
      <FormSwitch label="No hide filters" v-model="option.noHideFilters" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import DataTable from '@/components/DataTable'
import FormSwitch from '@/components/form/FormSwitch'
import FormButtonMenu from '@/components/form/FormButtonMenu'
export default {
  name: 'TableDemo',
  components: { DemoPanel, FormSwitch, DataTable, FormButtonMenu },
  data: () => ({
    headers: [
      { text: 'Produto', value: 'name' },
      { text: 'Tipo', value: 'type' },
      { text: 'Valor', value: 'price' },
      {
        text: 'Opções',
        value: 'opcoes',
        clickable: false
      }
    ],
    rows: [
      { name: 'Caixa de bombons', type: 'Chocolate', price: 'R$ 15,50' },
      { name: 'Maçã', type: 'Fruta', price: 'R$ 1,50' },
      { name: 'Leite', type: 'Alimento', price: 'R$ 4,20' },
      { name: 'Café', type: 'Alimento', price: 'R$ 6,10' }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    filters: [
      { label: 'Filtro 1', query: 'filter' },
      { label: 'Filtro 2', query: 'filter2' }
    ],
    option: {
      showSelect: false,
      singleSelect: false,
      noHideFilters: false
    }
  })
}
</script>
