<template>
  <DemoPanel name="FormRadioGroup">
    <v-col sm="12" md="4" lg="4">
      <FormRadioGroup
        v-model="model"
        :inline="option.inline"
        :radios="radios"
      ></FormRadioGroup>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="inline" v-model="option.inline" />
      <FormSwitch label="disabled" v-model="option.disabled" />
      <FormSwitch label="readonly" v-model="option.readonly" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import FormSwitch from '@/components/form/FormSwitch'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormRadioGroupDemo',
  components: { DemoPanel, FormRadioGroup, FormSwitch },
  data: () => ({
    model: false,
    option: {
      disabled: false,
      readonly: false,
      inline: false
    }
  }),
  validations: {
    model: {
      required
    }
  },
  computed: {
    radios() {
      return [
        {
          label: 'Radio 1',
          value: 'Radio1',
          disabled: this.option.disabled,
          readonly: this.option.readonly
        },
        {
          label: 'Radio 2',
          value: 'Radio2',
          disabled: this.option.disabled,
          readonly: this.option.readonly
        },
        {
          label: 'Radio 3',
          value: 'Radio3',
          disabled: this.option.disabled,
          readonly: this.option.readonly
        }
      ]
    }
  }
}
</script>
