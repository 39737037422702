<template>
  <DemoPanel name="FormRadio">
    <v-col sm="12" md="4" lg="4">
      <FormRadio
        v-model="model"
        label="Radio"
        :validation="$v.model"
        :disabled="option.disabled"
        :readonly="option.readonly"
        :val="true"
      ></FormRadio>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="disabled" v-model="option.disabled" />
      <FormSwitch label="readonly" v-model="option.readonly" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormRadio from '@/components/form/FormRadio'
import FormSwitch from '@/components/form/FormSwitch'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormRadioDemo',
  components: { DemoPanel, FormRadio, FormSwitch },
  data: () => ({
    model: false,
    option: {
      disabled: false,
      readonly: false
    }
  }),
  validations: {
    model: {
      required
    }
  }
}
</script>
