<template>
  <DemoPanel name="FormDatePicker">
    <v-col sm="12" md="4" lg="4">
      <FormField v-model="formatter" label="Formatador"></FormField>
    </v-col>
    <v-col sm="12" md="4" lg="4">
      <FormDatePicker
        v-model="birthday"
        :validation="$v.birthday"
        :formatter="formatter"
        :dense="option.dense"
        :solo="option.solo"
        :disabled="option.disabled"
        :readonly="option.readonly"
        label="Data"
      ></FormDatePicker>
    </v-col>
    <template v-slot:options>
      <FormSwitch label="dense" v-model="option.dense" />
      <FormSwitch label="solo" v-model="option.solo" />
      <FormSwitch label="disabled" v-model="option.disabled" />
      <FormSwitch label="readonly" v-model="option.readonly" />
    </template>
  </DemoPanel>
</template>

<script>
import DemoPanel from '../DemoPanel'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormSwitch from '@/components/form/FormSwitch'
import FormField from '@/components/form/FormField'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FormDatePickerDemo',
  components: { DemoPanel, FormDatePicker, FormField, FormSwitch },
  data: () => ({
    birthday: '',
    formatter: 'DD/MM/YYYY',
    option: {
      dense: false,
      solo: false,
      disabled: false,
      readonly: false
    }
  }),
  validations: {
    birthday: {
      required
    }
  }
}
</script>
